<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    :loading="loading"
    :side-valid="loading"
    submit-title="Delete"
    title="Delete the Tag"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('delete', tags)"
  >
    <div class="mb-4">
      You are going to delete tag <span
        v-for="(tag, index) in tags"
        :key="tag"
        class="bold-600"
      >{{ tag }}{{ index + 1 !== tags.length ? ',' : '' }} </span> . This action cannot be undone, and  this may influence the quality score.
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  name: 'DeleteTagsModal',
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    tags : {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
}
</script>