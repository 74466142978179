<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    submit-title="Merge"
    title="Do you confirm merging types ?"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('confirm')"
  >
    <div
      v-for="(typeM, index) in mergedTypes"
      :key="index"
      class="mb-1"
    >
      <div
        class="flex align-center space-between"
      >
        <div>
          {{ capitalizeFirstLetter(typeM) }}
        </div>
      </div>
    </div>

    <div class="mb-4 mt-3">
      Into existing type: <strong>{{ type }}</strong>
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

// Utils
import { capitalizeFirstLetter } from "@/utils/text-formatter";

export default {
  name: 'ConfirmMergeModal',
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    type :{
      type: String,
      default: ''
    },
    mergedTypes :{
      type: Array,
      default: () => []
    },
  },
  methods: {
    capitalizeFirstLetter,
  }
}
</script>