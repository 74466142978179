<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    submit-title="Delete"
    title="Delete the Types"
    overflowing
    width="400"
    v-on="$listeners"
    :side-valid="availableForDeletionTypes.length === 0"
    @close="$emit('close')"
    @submit="$emit('delete', availableForDeletionTypes)"
  >
    <div>
      <div class="mb-4" v-if="assignedToAddress.length">
        <p class="mb-2">
          You cannot remove these types because they are assigned to addresses.
        </p>
        <div
          v-for="(type) in assignedToAddress"
          :key="type"
          class="bold-600 mb-1"
        >
          {{ type }}
        </div>
      </div>
      <div class="mb-4" v-if="assignedToCluster.length">
        <p class="mb-2">
          You cannot remove these types because they are assigned to clusters.
        </p>
        <div
          v-for="(type) in assignedToCluster"
          :key="type"
          class="bold-600 mb-1"
        >
          {{ type }}
        </div>
      </div>
      <div class="mb-4" v-if="availableForDeletionTypes.length">
        <p class="mb-2">
          Do you want to remove the rest of the types?
        </p>
        <div
          v-for="(type) in availableForDeletionTypes"
          :key="type"
          class="bold-600 mb-1"
        >
          {{ type }}
        </div>
      </div>
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  name: 'DeleteTypesModal',
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    assignedToAddress :{
      type: Array,
      default: () => []
    },
    assignedToCluster :{
      type: Array,
      default: () => []
    },
    availableForDeletionTypes :{
      type: Array,
      default: () => []
    },
  },
}
</script>