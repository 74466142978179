<template>
  <div class="fullwidth tags-scoring pa-3">
    <div class="score-clock-title">
      Types Score
    </div>
    <div class="flex align-flex-end space-between mb-4 gap-3 m-column">
      <gl-input
        ref="typeName"
        v-model="name"
        class="fullwidth"
        clearable
        :height="40"
        label="type name"
        name="type"
        placeholder="Create a unique type name"
        rules="required|max:50|min:2"
        @blur="handleBlur"
        @clear="clearField"
      />
      <div class="flex gap-3 m-fullwidth align-end">
        <gl-input
          v-model="score"
          v-mask="'###'"
          class="flex-1 width-100"
          :height="40"
          label="type score"
          min="0"
          name="score"
          rules="required|max_value:100"
          type="number"
          @input="handleScoring"
        />
        <button
          class="gl-button gl-button--dark gl-button--padder action-btn max-w-100"
          data-cy="addType"
          :disabled="!name || name.length < 2 || name.length > 50 || !String(score)"
          @click="sendNewTag(name, Number(score))"
        >
          add type
        </button>
      </div>
    </div>
    <div class="mb-3 flex align-center">
      <gl-icon
        class="mr-2 gl-icon--disabled"
        :height="24"
        name="def-report"
        :width="24"
      />
      <div class="grey-text fs-12">
        Type Score can be set from 0 to 100. The bigger score is the more severe risk.
      </div>
    </div>
    <div class="flex space-between mt-3">
      <div class="bold-600 mb-3 fs-14">
        Current Types Score
      </div>
      <div class="flex m-display-none">
        <gl-menu-item
          class="mr-3"
          :disabled="checkedRows.length === 0"
          fullwidth
          icon="delete-action"
          :icon-height="24"
          :icon-width="24"
          label="Delete Selected"
          @click="removeTypeOpenModal(checkedRows.map(item => item.name))"
        />
        <gl-menu-item
          :disabled="checkedRows.length < 2"
          fullwidth
          icon="merge-action"
          :icon-height="24"
          :icon-width="24"
          label="Merge Selected"
          @click="mergeTypeOpenModal(checkedRows.map(item => item.name))"
        />
      </div>
    </div>
    <div class="flex align-flex-end space-between mb-4 mt-2">
      <gl-input
        v-model="search"
        class="mr-4 fullwidth m-mr-3"
        clearable
        data-cy="typeName"
        :height="40"
        label="TYPE NAME"
        name="type"
        placeholder="Search by a type name"
        width="100%"
        @clear="resetSearch"
      />
      <button
        class="gl-button gl-button--dark gl-button--padder action-btn"
        data-cy="addTypeSearch"
        @click="loadData(true)"
      >
        search
      </button>
    </div>
    <div class="m-flex t-display-none display-none mb-4">
      <gl-menu-item
        class="mr-3"
        :disabled="checkedRows.length === 0"
        fullwidth
        icon="delete-action"
        :icon-height="24"
        :icon-width="24"
        label="Delete Selected"
        @click="removeTypeOpenModal(checkedRows.map(item => item.name))"
      />
      <gl-menu-item
        :disabled="checkedRows.length < 2"
        fullwidth
        icon="merge-action"
        :icon-height="24"
        :icon-width="24"
        label="Merge Selected"
        @click="mergeTypeOpenModal(checkedRows.map(item => item.name))"
      />
    </div>
    <div class="table-wrap table-wrap__unoverflow gl-table-unshadow">
      <o-table
        v-if="typesData.length > 0"
        backend-sorting
        checkable
        checkbox-position="left"
        :checked-rows.sync="checkedRows"
        class="table__overflow-auto"
        :class="{ 'o-table-has-pagination': typesData.length && totalPages > 1 }"
        :data="typesData"
        :default-sort="[sortField, sortOrder]"
        :default-sort-direction="defaultSortOrder"
        hoverable
        :is-row-checkable="(row) => !forbidDeleteAndRename(row)"
        :loading="loading"
        :mobile-cards="false"
        sort-icon="chevron-up"
        @check="checkRow"
        @sort="onSort"
      >
        <o-table-column
          v-slot="props"
          field="name"
          label="type name"
          sortable
        >
          <div class="flex">
            <gl-mark
              v-if="props.row.score === null"
              class="fit-content mr-2"
              label="New"
            />
            <div>
              {{ capitalizeFirstLetter(props.row.name) }}
            </div>
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="score"
          label="Score"
          sortable
        >
          <div class="score-mark">
            <GlScoreLevelChip
              v-if="!loading"
              :level="props.row.score ? props.row.score.toFixed(0) : 0"
              score
            />
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          align="right"
          field="actions"
          label="Actions"
        >
          <div class="flex">
            <div style="min-width: 30px">
              <gl-icon
                v-if="!forbidDeleteAndRename(props.row)"
                class="pointer mr-2"
                :height="24"
                name="delete-action"
                :width="24"
                @click="removeTypeOpenModal(props.row.name)"
              />
            </div>
            <div>
              <gl-icon
                v-if="!forbidEdit(props.row)"
                class="pointer"
                :height="24"
                name="edit-action"
                :width="24"
                @click="editType(props.row.name, Number(props.row.score), props.row.status)"
              />
            </div>
          </div>
        </o-table-column>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex space-between pa-2">
        <div class="flex align-center pa-3 m-column m-pa-0 m-pt-2">
          <div class="mr-2 fs-14 bold m-fs-12 m-mb-3">
            Rows per page:
          </div>
          <vSelect
            v-model="perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            @input="countChange"
          />
        </div>
        <o-pagination
          v-if="typesData.length && totalPages > 1"
          class="stat-pagination"
          :current.sync="currentPage"
          order="right"
          :per-page="perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
    <EditTypeModal
      v-if="showEditModal"
      v-model="showEditModal"
      :loading="loading"
      :score="editTypeScore"
      :status="editTypeStatus"
      :type="editTypeName"
      @close="showEditModal = false"
      @edit="updateType"
    />
    <confirmUpdateCalcModal
      v-if="showConfirmModal"
      v-model="showConfirmModal"
      :message="message"
      @close="showConfirmModal = false"
      @confirm="confirm"
    />
    <DeleteTypesModal
      v-if="showDelete"
      v-model="showDelete"
      :loading="deleteLoading"
      :types="removedTypes"
      @close="showDelete = false"
      @delete="removeType"
    />
    <DeleteAssignedTypesModal
      v-if="showAssignedModal"
      v-model="showAssignedModal"
      :assigned-to-address="assignedToAddress"
      :assigned-to-cluster="assignedToCluster"
      :available-for-deletion-types="availableForDeletionTypes"
      @close="showAssignedModal = false"
      @delete="removeType"
    />
    <MergeTypesModal
      v-if="showMergedModal"
      v-model="showMergedModal"
      :loading="mergeLoading"
      :types="allTypes"
      :types-for-merge="selectedForMergeTypes"
      @close="showMergedModal = false"
      @merge="sendMergeTypes"
    />
  </div>
</template>

<script>
//Vuex
import {mapActions, mapState} from "vuex";

// Mixins
import sidebarTrackingMixin from "@/assets/mixins/sidebarTrackingMixin";
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

// Components
import GlMenuItem from '@/components/gl-menu-item'
import GlInput from '@/components/gl-input'
import vSelect from 'vue-select'
import GlLoader from '@/components/gl-loader'
import GlMark from '@/components/gl-mark'
import GlIcon from '@/components/gl-icon'
import EditTypeModal from '../modals/EditTypeModal'
import DeleteTypesModal from '../modals/DeleteTypesModal'
import MergeTypesModal from '../modals/MergeTypesModal'
import DeleteAssignedTypesModal from '../modals/DeleteAssignedTypesModal'
import GlScoreLevelChip from '@/components/gl-score-level-chip'
import confirmUpdateCalcModal from "@/pages/scoring/modals/confirmUpdateCalcModal";

// Libs
import _ from 'lodash'

// Utils
import {capitalizeFirstLetter, formatterRecalcApproveMessage} from "@/utils/text-formatter";

export default {
  name: 'TypesScoring',
  components: {
    confirmUpdateCalcModal,
    GlMenuItem,
    EditTypeModal,
    DeleteTypesModal,
    GlScoreLevelChip,
    DeleteAssignedTypesModal,
    MergeTypesModal,
    GlInput,
    GlMark,
    GlIcon,
    GlLoader,
    vSelect,
  },
  mixins: [sidebarTrackingMixin, deviceWidthMixin],
  data() {
    return {
      showConfirmModal: false,
      message: '',
      loading: false,
      deleteLoading: false,
      pagesOptions: [5, 10,20, 50, 100],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      defaultSortOrder: 'desc',
      sortField: 'name',
      sortOrder: 'desc',
      name: '',
      search: '',
      score: 0,
      allTypes: [],
      typesData: [],
      checkedRows: [],
      removedTypes: [],
      selectedForMergeTypes: [],
      assignedToAddress: [],
      assignedToCluster: [],
      availableForDeletionTypes: [],
      editTypeName: '',
      newType: '',
      newScore: 0,
      editTypeScore: 0,
      editTypeStatus: '',
      showEditModal: false,
      showMergedModal: false,
      showAssignedModal: false,
      showDelete: false,
      mergeLoading: false,
      skipCheck: false,
    }
  },
  computed: {
    ...mapState('analytics', ['coinType']),
  },
  watch: {
    coinType: {
      handler() {
        this.loadData()
      },
      immediate: true
    }
  },
  created() {
    this.perPage = localStorage.getItem('type-scoring-per-page') || this.perPage
    localStorage.setItem('type-scoring-per-page', this.perPage)
  },
  async mounted() {
    await this.loadData()
     this.getTypes({ count: 1000 }).then(({ data: { typeData } }) => {
       this.allTypes = typeData.map(type => capitalizeFirstLetter(type.name))
     })
  },
  methods: {
    capitalizeFirstLetter,
    formatterRecalcApproveMessage,
    ...mapActions({
      getTypes: 'types/getTypes',
      deleteType: 'types/deleteType',
      addType: 'types/addType',
      editTypesData: 'types/editType',
      mergeTypes: 'types/mergeTypes'
    }),
    confirm() {
      this.showEditModal = false
      this.skipCheck = true
      this.updateType(this.newType, this.newScore)
    },
    forbidEdit(type) {
      if (!type.status) return false
      return type.status && type.status.includes('forbidEdit')
    },
    forbidDeleteAndRename(type) {
      if (!type.status) return false
      return type.status.includes('forbidDeleteAndRename')
    },
    handleScoring(score) {
      if (score && Number(score) > 100) {
        this.score = 100
      }
    },
    removeType(names) {
      this.deleteLoading = true
      this.deleteType({ names }).then(({ data, success }) => {
        if (success) {
          this.currentPage = 1
          this.loadData()
          this.removedTypes = []
          this.showDelete = false
          this.showAssignedModal = false
          this.checkedRows = []
        } else {
          this.showAssignedModal = true
          this.assignedToAddress = data.assignedToAddress || []
          this.assignedToCluster = data.assignedToCluster || []

          const selectedTypesName = this.checkedRows.map(type => type.name)

          this.formattedRemoveTypes(selectedTypesName, this.assignedToAddress, this.assignedToCluster)
        }
      }).finally(() => this.deleteLoading = false)
    },
    formattedRemoveTypes(selectedTypes, assignedToAddress, assignedToCluster) {
      let res = _.difference(selectedTypes, assignedToAddress)
      this.availableForDeletionTypes = _.difference(res, assignedToCluster)
    },
    sendMergeTypes(toType, fromTypes, score) {
      this.mergeLoading = true

      this.mergeTypes({ toType, fromTypes, score: Number(score) }).then(({ success }) => {
        if (success) {
          this.$toasted.global.success({ message: `New type ${toType} with score ${score} was successfully created.`})
          this.checkedRows = []
          this.loadData()
        }

        this.showMergedModal = false
      }).finally(() => {
        this.mergeLoading = false
      })
    },
    removeTypeOpenModal(names) {
      this.removedTypes = Array.isArray(names) ? names : [names]
      this.showDelete = true
    },
    mergeTypeOpenModal(names) {
      this.selectedForMergeTypes = [... new Set(names.map(name => capitalizeFirstLetter(name)))]
      this.showMergedModal = true
    },
    resetSearch() {
      this.search = ''
      this.loadData()
    },
    editType(name, score, status = '') {
      this.editTypeName = name
      this.editTypeScore = Number(score)
      this.editTypeStatus = status
      this.showEditModal = true
    },
    updateType(name, score) {
      this.message = ''
      this.newType = ''
      this.newScore = 0
      this.loading = true

      this.editTypesData({ name: this.editTypeName, newName: name, score: Number(score), skipCheck: this.skipCheck }).then(({ success, data }) => {
        if (success) {
          this.editTypeName = ''
          this.editTypeScore = ''
          this.showEditModal = false
          this.showConfirmModal = false
          this.skipCheck = false
          this.loadData()
        } else {
          this.newType = name
          this.newScore = score
          if (data.showPopup || data?.response?.messageData) {
            this.message = this.formatterRecalcApproveMessage ? this.formatterRecalcApproveMessage(data?.response?.messageData) : data.message
            this.showConfirmModal = true
          } else this.$toasted.global.error({ message: `${data.message}`})
        }
      }).finally(() => {
        this.loading = false
      })
    },
    sendNewTag(name, score) {
      this.addType({ name, score }).then(({ success, data }) => {
        if (success) {
          this.$toasted.global.success({ message: `New type ${name} with score ${score} was successfully created.`})
          this.loadData()
        } else {
          this.$toasted.global.error({ message: `${data.message}`})
        }
      })
    },
    clearField() {
      this.name = ''
      this.$refs.typeName.resetError()
    },
    handleBlur() {
      if (!this.name) this.$refs.typeName.resetError()
    },
    loadData(isNewSearch = false) {
      if (isNewSearch) {
        this.currentPage = 1
      }
      this.loading = true
      const sendParams = this.formattedSendData()
      this.getTypes(sendParams).then(({ data: { total, typeData } }) => {
        this.checkUntackedLabels()
        this.typesData = typeData
        this.total = total
        this.totalPages = Math.ceil(this.total / this.perPage)
        this.loading = false
        this.checkingRows()
      })
    },
    checkingRows() {
      this.$nextTick(() => {
        this.typesData.map(v => {
          if (this.checkedRows.find(item => item._id === v._id)) {
            this.checkedRows.push(v)
          }
        })
      })
    },
    onSort(field, order) {
      this.sortOrder = order
      this.sortField = field
      this.loadData()
    },
    checkRow(data) {
      this.checkedRows = data
    },
    checkAll(data) {
      console.log(data)
    },
    formattedSendData() {
      this.perPage = localStorage.getItem('type-scoring-per-page') || this.perPage

      return {
        sortField: this.sortField || undefined,
        count: this.perPage,
        search: this.search,
        skip: (this.currentPage - 1) * this.perPage,
        sortOrder: this.sortOrder || undefined
      }
    },
    countChange() {
      // this.checkedRows = []
      localStorage.setItem('type-scoring-per-page', this.perPage)
      this.loadData()
    },
    pageChange(event) {
      // this.checkedRows = []
      this.currentPage = event
      this.loadData()
    },
  }
}
</script>

<style>
.tags-scoring {
  background: #fff;
}

.score-clock-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.stat-select {
  width: 160px;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}

.tags-scoring {
  border-radius: 3px;
}
</style>
